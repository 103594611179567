import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as turf from "@turf/turf";
// map / GIS
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// redux
import {
  HexbinLayerTypes,
  selectHexbinLayers,
  setActiveHexbinLayer,
} from "src/features/densityMapSlice";
// MUI
import {
  Box, Button, IconButton, Grid, Card,
  Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SettingsIcon from '@mui/icons-material/Settings';
// components
import MapLegend from "./MapLegend";
import MapInfoPanel from "./MapInfoPanel";
import SettingsFormDialog from "./SettingsFormDialog";
// util
import {
  initHighlightHexbinLayer,
  updateHighlightHexbinLayer,
  initDensityHexbinLayer,
  updateDensityHexbinLayer,
  initPassCountHexbinLayer,
  updatePassCountHexbinLayer,
  initTemperatureHexbinLayer,
  updateTemperatureHexbinLayer,
  initPointsLayer,
  updatePointsLayer,
  initExpandedPointsLayer,
  updateExpandedPointsLayer,
  initHeadingLayer,
  updateHeadingLayer,
  initRectangleLayer,
  updateRectangleMapLayer,
  initRollerIconLayer,
  updateRollerIconLayer,
  initProjectIconLayer,
  updateProjectIconLayer,
  initCoreSampleIconLayer,
  updateCoreSampleIconLayer,
  initHoverPopups,
} from './mapUtils';
// assets
import { ArrowIcon } from "src/assets";
import { RollerIcon } from "src/assets";
import { RtdMapIcon } from "src/assets";
import { CoreSampleMapIcon } from "src/assets";

// ----------------------------------------------------------------------

const PwlCell = styled(TableCell)(({ theme }) => ({
  padding: '10px 6px',
}));

const DEFAULT_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: []
}

export const DEFAULT_MAP_LAYERS = [
  {
    id: 'hexbin',
    label: 'Hexbin',
    show: true
  },
  {
    id: 'points',
    label: 'Points',
    show: false
  },
  {
    id: 'expanded_points',
    label: 'Expanded Points',
    show: false
  },
  {
    id: 'heading',
    label: 'Heading',
    show: false
  },
  {
    id: 'rectangle',
    label: 'Rectangle',
    show: false
  }
];

export const DEFAULT_ICON_INFO = {
  coordinates: [-98.5556199, 39.8097343],
  heading: 0,
}

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_APIKEY
const DEFAULT_CENTER = [-98.5556199, 39.8097343];
const DEFAULT_ZOOM = 2;
const DEFAULT_MAP_LEGEND = {
  layerType: '',
  label: '',
  rangeUnits: '',
  colorRange: [],
}

const HIGHLIGHT_HEXBIN_LAYER_INFO = {
  source_name: 'highlightHexbinSource',
  layer_name: 'highlightHexbinLayer',
};
const POINTS_LAYER_INFO = {
  source_name: 'pointsSource',
  layer_name: 'pointsLayer'
};
const EXPANDED_POINTS_LAYER_INFO = {
  source_name: 'expandedPointsSource',
  layer_name: 'expandedPointsLayer'
};
const HEADING_LAYER_INFO = {
  source_name: 'headingSource',
  layer_name: 'headingLayer',
  icon_name: 'arrow-icon',
};
const RECT_LAYER_INFO = {
  source_name: 'rectangleSource',
  layer_name: 'rectangleLayer'
};
const ROLLER_ICON_LAYER_INFO = {
  source_name: 'iconSource',
  layer_name: 'iconLayer',
  icon_name: 'icon-info'
};
const CORE_SAMPLE_ICON_LAYER_INFO = {
  source_name: 'coreSampleiconSource',
  layer_name: 'coreSampleIconLayer',
  icon_name: 'core-sample-icon'
};
const PROJECT_ICON_LAYER_INFO = {
  source_name: 'projectIconSource',
  layer_name: 'projectIconLayer',
  icon_name: 'project-icon-info'
};

// ----------------------------------------------------------------------

const DensityMap = (props) => {
  const {
    dataVersion, cameraConfig, showMapLayers, iconInfo,
    origGeojsonData, geojsonData, hexGridGeoJson, rectGeojson,
    pwlTable, coreSamples,
  } = props;
  const dispatch = useDispatch();
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [legendInfo, setLegendInfo] = useState(DEFAULT_MAP_LEGEND);
  const hexbinLayers = useSelector(selectHexbinLayers);
  const [selectedHexbin, setSelectedHexbin] = useState(null);

  // initialize map
  useEffect(() => {
    if (!hexbinLayers) return;
    if (map.current) return;

    mapboxgl.accessToken = MAPBOX_API_KEY;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/satellite-streets-v11`,
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    //map.current.addControl(new mapboxgl.FullscreenControl());

    map.current.on('load', () => {
      map.current.loadImage(ArrowIcon, (error, image) => {
        if (error) throw error;

        map.current.addImage(HEADING_LAYER_INFO.icon_name, image);
      });

      map.current.loadImage(RollerIcon, (error, image) => {
        if (error) throw error;

        map.current.addImage(ROLLER_ICON_LAYER_INFO.icon_name, image);
      });

      map.current.loadImage(RtdMapIcon, (error, image) => {
        if (error) throw error;

        map.current.addImage(PROJECT_ICON_LAYER_INFO.icon_name, image);
      });

      map.current.loadImage(CoreSampleMapIcon, (error, image) => {
        if (error) throw error;

        map.current.addImage(CORE_SAMPLE_ICON_LAYER_INFO.icon_name, image);
      });

      const densityLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.density);
      const passCountLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.pass_count);
      const temperatureLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.temperature);

      initDensityHexbinLayer(map, densityLayer, onHexbinClick);
      initPassCountHexbinLayer(map, passCountLayer, onHexbinClick);
      initTemperatureHexbinLayer(map, temperatureLayer, onHexbinClick);

      // must create the highlight layer after the hexbin layers
      initHighlightHexbinLayer(map, HIGHLIGHT_HEXBIN_LAYER_INFO);

      initPointsLayer(map, POINTS_LAYER_INFO);
      initExpandedPointsLayer(map, EXPANDED_POINTS_LAYER_INFO);
      initHeadingLayer(map, HEADING_LAYER_INFO);
      initRectangleLayer(map, RECT_LAYER_INFO);
      initRollerIconLayer(map, ROLLER_ICON_LAYER_INFO);
      initCoreSampleIconLayer(map, CORE_SAMPLE_ICON_LAYER_INFO);
      initProjectIconLayer(map, PROJECT_ICON_LAYER_INFO);

      const hoverLayerNames = [
        densityLayer.layer_info.layer_name,
        passCountLayer.layer_info.layer_name,
        temperatureLayer.layer_info.layer_name,
        CORE_SAMPLE_ICON_LAYER_INFO.layer_name,
      ];

      initHoverPopups(map, hoverLayerNames);
    });

    updateLegend();

    // clean up on unmount
    return () => {
      map.current.remove();
    };
  }, []);

  // update core sample icon layer
  useEffect(() => {
    if (!map.current) return;

    let newCoreSampleGeojson = DEFAULT_FEATURE_COLLECTION;

    if (coreSamples?.length > 0) {
      const coreSamplePoints = coreSamples.map((coreSample) => {
        const coordinates = [coreSample.longitude, coreSample.latitude];

        const properties = {
          core: coreSample.core,
          estimate: coreSample.estimate ? coreSample.estimate : null,
          lab_data: coreSample.lab_data
        };

        return turf.point(coordinates, properties);
      });

      newCoreSampleGeojson = turf.featureCollection(coreSamplePoints);
    }

    updateCoreSampleIconLayer(map, CORE_SAMPLE_ICON_LAYER_INFO, newCoreSampleGeojson);
  }, [coreSamples]);

  // update map on hexbin click
  useEffect(() => {
    if (!map.current) return;

    updateHighlightHexbinLayer(map, HIGHLIGHT_HEXBIN_LAYER_INFO, selectedHexbin);
  }, [selectedHexbin]);

  // update map from data changes
  useEffect(() => {
    if (!map.current) return;

    updateMap();
    updateLegend();
  }, [showMapLayers, dataVersion, hexbinLayers]);

  // update camera
  useEffect(() => {
    if (!cameraConfig || !cameraConfig.center || !map.current) return;

    const bounds = map.current.getBounds();
    const pointToCheck = new mapboxgl.LngLat(cameraConfig.center[0], cameraConfig.center[1]);

    // only update the camera if the zoom is changed or the point is not already in view
    if (!bounds.contains(pointToCheck)) {
      map.current.flyTo({ ...cameraConfig });
    } else if (cameraConfig.zoom && cameraConfig.zoom !== map.current.getZoom()) {
      map.current.flyTo({ ...cameraConfig });
    }
  }, [cameraConfig]);

  const updateMap = () => {
    const densityLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.density);
    const passCountLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.pass_count);
    const temperatureLayer = hexbinLayers.find(layer => layer.id === HexbinLayerTypes.temperature);

    const showDensityLayer = checkShowLayer('hexbin', HexbinLayerTypes.density);
    updateDensityHexbinLayer(map, densityLayer, showDensityLayer, hexGridGeoJson);

    const showPassCountLayer = checkShowLayer('hexbin', HexbinLayerTypes.pass_count);
    updatePassCountHexbinLayer(map, passCountLayer, showPassCountLayer, hexGridGeoJson);

    const showTemperatureLayer = checkShowLayer('hexbin', HexbinLayerTypes.temperature);
    updateTemperatureHexbinLayer(map, temperatureLayer, showTemperatureLayer, hexGridGeoJson);

    const showPointsLayer = checkShowLayer('points');
    updatePointsLayer(map, POINTS_LAYER_INFO, showPointsLayer, origGeojsonData);

    const showExpandedPointsLayer = checkShowLayer('expanded_points');
    updateExpandedPointsLayer(map, EXPANDED_POINTS_LAYER_INFO, showExpandedPointsLayer, geojsonData);

    const showHeadingLayer = checkShowLayer('heading');
    updateHeadingLayer(map, HEADING_LAYER_INFO, showHeadingLayer, origGeojsonData);

    const showRectangleLayer = checkShowLayer('rectangle');
    updateRectangleMapLayer(map, RECT_LAYER_INFO, showRectangleLayer, rectGeojson);

    updateRollerIconLayer(map, ROLLER_ICON_LAYER_INFO, iconInfo);

    if (origGeojsonData?.features.length > 0) {
      //using the first point to avoid recalcualting the center for every update
      const projectIconInfo = {
        coordinates: origGeojsonData.features[0].geometry.coordinates
      };
      updateProjectIconLayer(map, PROJECT_ICON_LAYER_INFO, projectIconInfo);
    }
  };

  const getActiveHexbinLayer = () => {
    const activeLayer = hexbinLayers.find(layer => layer.show);

    if (!activeLayer) {
      return hexbinLayers[0]; // always return a layer
    }

    return activeLayer;
  }

  const checkShowLayer = (layerId, hexbinType) => {
    let showMapLayer = showMapLayers.find(layer => layer.id === layerId);

    if (layerId === 'hexbin') {
      const activeHexbinLayer = getActiveHexbinLayer();
      return showMapLayer.show && activeHexbinLayer.id === hexbinType;
    } else {
      return showMapLayer.show;
    }
  }
 
  const updateLegend = () => {
    const hexbinLayer = getActiveHexbinLayer();

    setLegendInfo({
      id: hexbinLayer.id,
      label: hexbinLayer.label,
      rangeUnits: hexbinLayer.layer_info.range_units,
      colorRange: hexbinLayer.layer_info.color_range
    });
  }

  const handleHexbinLayerChange = (mapLayer, event) => {
    dispatch(setActiveHexbinLayer({ id: mapLayer.id }));
  };  

  const onSettingsClick = () => {
    setSettingsOpen(true);
  }

  const onSettingsClose = () => {
    setSettingsOpen(false);
  }

  const goToCorePosition = (coreSample) => {
    const coordinates = [coreSample.longitude, coreSample.latitude];

    map.current.flyTo({
      center: coordinates,
      zoom: 21,
      essential: true
    });
  }

  const onHexbinClick = (feature) => {
    setSelectedHexbin(feature);
  }

  const onCloseInfoPanel = () => {
    setSelectedHexbin(null);
  }

  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={9}>
        <Box sx={{ position: 'relative', minHeight: '500px', height: '100%' }}>
          <Box ref={mapContainer} sx={myStyle.map} />
          <Box style={myStyle.layersContainer}>
            <Box 
              sx={{
                display: 'flex',
                alignItems: {
                  xs: 'start',
                  lg: 'center',
                },
                flexDirection: {
                  xs: 'column',
                  lg: 'row',
                }
              }}
            >
              <IconButton sx={{ mr: 1, color: 'rgba(0, 0, 0, 0.7)' }} size="small" onClick={onSettingsClick}>
                <SettingsIcon  />
              </IconButton>

              {hexbinLayers && hexbinLayers.map((hexbinLayer) => (
                <label key={hexbinLayer.id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <input
                    type="radio"
                    checked={hexbinLayer.show}
                    onChange={(e) => handleHexbinLayerChange(hexbinLayer, e)}
                    name="hexbinLayer"
                  />
                  <span style={myStyle.layerLabel}>{hexbinLayer.label}</span>
                </label>
              ))}
            </Box>
          </Box>

          <Box sx={myStyle.legendContainer}>
            <Box sx={myStyle.legendAlignment}>
              <MapLegend label={legendInfo.label} colorRange={legendInfo.colorRange} rangeUnits={legendInfo.rangeUnits } />
            </Box>
          </Box>

          {selectedHexbin && (
            <Box
              sx={{
                ...myStyle.infoPanelContainer,
                height: {
                  lg: 'calc(100% - 74px)',
                  md: 'calc(100% - 138px)',
                  sm: 'calc(100% - 138px)',
                  xs: 'calc(100% - 138px)',
                }
              }}
            >
              <MapInfoPanel hexbin={selectedHexbin} points={origGeojsonData} onClose={onCloseInfoPanel} />
            </Box>
          )}

          <SettingsFormDialog open={settingsOpen} onClose={onSettingsClose} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} sx={{ maxHeight: '100%', height: '100%' }}>
        <Card sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
          {pwlTable.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={myStyle.tableTitle}>PWL</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <PwlCell sx={{ color: 'white', textAlign: 'right' }}>Density Range</PwlCell>
                      <PwlCell sx={{ color: 'white' }}>Distribution</PwlCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pwlTable.map((row, index) => (
                      <TableRow key={index}>
                        <PwlCell sx={{ textAlign: 'right' }}>{row.range}</PwlCell>
                        <PwlCell>{row.percent}%</PwlCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {coreSamples.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={myStyle.tableTitle}>Core Sample</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <PwlCell />
                      <PwlCell colSpan="2" sx={{ textAlign: 'center', color: 'white' }}>%MTD</PwlCell>
                    </TableRow>
                    <TableRow>
                      <PwlCell sx={{ color: 'white' }}>Name</PwlCell>
                      <PwlCell sx={{ color: 'white' }}>RTD</PwlCell>
                      <PwlCell sx={{ color: 'white' }}>Core</PwlCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coreSamples.map((row, index) => (
                      <TableRow key={index}>
                        <PwlCell>
                          <Button sx={{ height: '24px' }} onClick={() => goToCorePosition(row)}>
                            {row.core}
                          </Button>
                        </PwlCell>
                        <PwlCell>{row.estimate ? row.estimate.toFixed(1) : '-'}</PwlCell>
                        <PwlCell>{row.lab_data}</PwlCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

        </Card>
      </Grid>
    </Grid>
  );
};

export default DensityMap;

const myStyle = {
  mapWrap: {
    position: 'relative',
    width: '100%',
    height: '76vh'
  },
  map: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  layersContainer: {
    backgroundColor: '#ffffff7d',
    borderRadius: '3px',
    border: '1px solid #a1a1a1',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    padding: '5px 10px',
    fontSize: '11px',
  },
  layerLabel: {
    marginLeft: '5px',
    fontSize: '14px',
  },
  legendContainer: {
    position: 'absolute',
    bottom: '44px',
    right: '10px',
    height: 'calc(100% - 44px)'
  },
  legendAlignment: {
    display: 'flex',
    alignItems: 'end',
    height: '100%'
  },
  infoPanelContainer: {
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
  tableTitle: {
    color: '#515151',
  },
};